import { memo, useMemo } from 'react';
import { ConfigurationProvider } from 'contexts';

const ConfigurationWrapper = ({ children }) => {
  const {
    NODE_ENV = '',
    REACT_APP_ENVIRONMENT = '',
    REACT_APP_API_URL = '',
    REACT_APP_API_PORT = '',
    REACT_APP_MQTT_BROKER_URL = '',
    REACT_APP_MQTT_BROKER_PORT = '',
    REACT_APP_MQTT_BROKER_USERNAME = '',
    REACT_APP_MQTT_BROKER_PASSWORD = ''
  } = process.env;

  const {
    API_URL: apiUrl = REACT_APP_API_URL,
    API_PORT: apiPort = REACT_APP_API_PORT,
    MQTT_BROKER_URL: mqttBrokerUrl = REACT_APP_MQTT_BROKER_URL,
    MQTT_BROKER_PORT: mqttBrokerPort = REACT_APP_MQTT_BROKER_PORT,
    MQTT_BROKER_USERNAME: mqttBrokerUsername = REACT_APP_MQTT_BROKER_USERNAME,
    MQTT_BROKER_PASSWORD: mqttBrokerPassword = REACT_APP_MQTT_BROKER_PASSWORD,
    ENVIRONMENT: environment = REACT_APP_ENVIRONMENT,
    nodeEnv = NODE_ENV
  } = window;

  const configuration = useMemo(() => ({
    apiUrl,
    apiPort,
    mqttBrokerUrl,
    mqttBrokerPort,
    mqttBrokerUsername,
    mqttBrokerPassword,
    environment,
    nodeEnv
  }), [apiPort, apiUrl, environment, nodeEnv, mqttBrokerUrl, mqttBrokerPort, mqttBrokerUsername, mqttBrokerPassword]);

  return (
    <ConfigurationProvider value={{ ...configuration }}>
      {children}
    </ConfigurationProvider>
  );
};

ConfigurationWrapper.displayName = 'ConfigurationWrapper';

export default memo(ConfigurationWrapper);