import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeWrapper } from 'rhi-ui';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  AuthWrapper,
  ConfigurationWrapper,
  MQTTWrapper,
  PagesWrapper
} from 'wrappers';

const queryClient = new QueryClient();

const App = () => (
  <ThemeWrapper>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <SnackbarProvider
        autoHideDuration={3000}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <AuthWrapper>
          <ConfigurationWrapper>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <MQTTWrapper>
                <BrowserRouter>
                  <PagesWrapper />
                </BrowserRouter>
              </MQTTWrapper>
            </QueryClientProvider>
          </ConfigurationWrapper>
        </AuthWrapper>
      </SnackbarProvider>
    </LocalizationProvider>
  </ThemeWrapper>
);

export default App;
