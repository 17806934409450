import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { memo, useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import { axiosInstance } from 'services';
import { FormProvider, useForm } from 'react-hook-form';
import RegisterAssetForm from './RegisterAssetForm';

const RegisterAssetDialog = ({ isOpen = false, onClose = () => {} }) => {
  const queryClient = useQueryClient();

  const { mutate: registerAssetMutation } = useMutation({
    mutationFn: async ({ assetId: asset_id, qtrackId: qtrack_id }) => {
      const result = await axiosInstance.post('/v1/register_asset', {
        asset_id,
        qtrack_id
      });
      return result;
    },
    onSuccess: (data, requestData) => {
      queryClient.setQueryData(['asset_summary'], (({
        assets_registered_count,
        assets_available_to_register_count,
        // eslint-disable-next-line no-shadow
        assets_available_to_register
      }) => ({
        assets_registered_count: assets_registered_count + 1,
        assets_available_to_register_count: assets_available_to_register_count === 0
          ? 0
          : assets_available_to_register_count - 1,
        assets_available_to_register: assets_available_to_register.filter((assetId) => assetId !== requestData?.assetId)
      })));
      notification.open({
        message: 'Asset has been registered',
        type: 'success',
        placement: 'bottom'
      });
    },
    onError: ({ response }) => {
      notification.open({
        message: 'Cannot register an asset',
        description: response?.data?.detail || '',
        type: 'error',
        placement: 'bottom'
      });
    }
  });

  const handleRegisterAsset = useCallback((values) => {
    registerAssetMutation(values);
  }, []);

  const registerAssetForm = useForm({
    defaultValues: {
      qtrackId: '',
      assetId: ''
    },
    reValidateMode: 'onChange',
    mode: 'onChange'
  });
  const { handleSubmit, reset } = registerAssetForm;

  const handleDialogClose = useCallback(() => {
    reset();
    onClose();
  });

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>Register Asset</DialogTitle>
      <FormProvider {...registerAssetForm}>
        <form onSubmit={handleSubmit(handleRegisterAsset)}>
          <DialogContent>
            <RegisterAssetForm />
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="outlined"
              onClick={handleDialogClose}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
            >
              Add
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

RegisterAssetDialog.displayName = 'RegisterAssetDialog';

export default memo(RegisterAssetDialog);