/* eslint-disable no-underscore-dangle */
/* eslint-disable global-require */
import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  MapContainer,
  Marker,
  ScaleControl,
  TileLayer,
  Tooltip
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { isNull } from 'lodash';
import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { axiosInstance } from 'services';
import AssetDialog from './AssetDialog';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const MAP_CENTER = [20.6752, -103.3473];

const MapComponent = () => {
  const [selectedAssetData, setSelectedAssetData] = useState(null);

  const { data: { data: assets = [] } = {} } = useQuery({
    queryKey: ['registered_assets_details'],
    queryFn: async () => {
      const response = await axiosInstance.get('/v1/registered_assets_details');
      return response;
    },
    refetchInterval: 10000
  });

  return (
    <>
      <MapContainer
        center={MAP_CENTER}
        zoom={60}
        scrollWheelZoom
        style={{ width: '100%', height: '100%', zIndex: '0' }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <ScaleControl imperial={false} />
        <MarkerClusterGroup
          disableClusteringAtZoom={17}
          spiderfyOnMaxZoom
          removeOutsideVisibleBounds
          chunkedLoading
        >
          {(assets || [])?.map((asset) => (
            <Marker
              key={asset.asset_id}
              position={[asset?.data?.lat || 0, asset?.data?.lng || 0]}
              eventHandlers={{
                click: () => setSelectedAssetData(asset)
              }}
            >
              <Tooltip permanent>{asset.asset_id}</Tooltip>
            </Marker>
          ))}
        </MarkerClusterGroup>
      </MapContainer>
      <AssetDialog
        open={!isNull(selectedAssetData)}
        onClose={() => setSelectedAssetData(null)}
        asset={selectedAssetData}
      />
    </>
  );
};

export default MapComponent;
