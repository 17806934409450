import { memo, useEffect } from 'react';
import mqtt from 'mqtt';
import { Flex, Typography, notification } from 'antd';
import useRegisteredAssets from '../containers/ReportGeneration/hooks/useRegisteredAssets';
import { useConfiguration } from '../hooks';

const MQTTWrapper = ({ children }) => {
  const { mqttBrokerUrl, mqttBrokerUsername, mqttBrokerPassword } = useConfiguration();
  const { data: { corresponding_trackers_registered = [] } = {}, isLoading } = useRegisteredAssets();

  useEffect(() => {
    if (isLoading || !corresponding_trackers_registered.length) return;

    const mqttClient = mqtt.connect(
      mqttBrokerUrl,
      { username: mqttBrokerUsername, password: mqttBrokerPassword, path: '/mqtt' }
    );

    mqttClient.on('connect', () => {
      corresponding_trackers_registered.forEach((trackerId) => {
        mqttClient.subscribe(`qt/report/${trackerId}/status`);
      });
      console.log('MQTT connected for the following assets: ', corresponding_trackers_registered.join(', '));
    });

    mqttClient.on('error', (error) => {
      try {
        console.log('MQTT Error:', error);
      } catch (err) {
        console.log('Error in error handler:', err);
      }
    });

    mqttClient.stream.on('error', (error) => {
      console.log(error);
      console.error('Connection error:', error);
    });

    mqttClient.on('close', () => {
      console.log('MQTT Connection closed');
    });

    mqttClient.on('message', (topic, message) => {
      const messageText = new TextDecoder().decode(message);
      const parsedMessage = JSON.parse(messageText || '');
      const {
        status: reportGenerationStatus, tracker_id, asset_id, report_id, info
      } = parsedMessage || {};
      notification.open({
        message: reportGenerationStatus === 'REPORT_GENERATION_SUCCEEDED'
          ? 'Report has been generated' : reportGenerationStatus === 'REPORT_GENERATION_FAILED'
            ? 'Report cannot be generated' : '',
        description: (
          <Flex vertical>
            <Typography><b>Report ID:</b> {report_id || '-'}</Typography>
            <Typography><b>Tracker ID:</b> {tracker_id || '-'}</Typography>
            <Typography><b>Asset ID:</b> {asset_id || '-'}</Typography>
            {info && reportGenerationStatus === 'REPORT_GENERATION_SUCCEEDED' && <Typography><b>Link:</b> {info || '-'}</Typography>}
          </Flex>
        ),
        type: reportGenerationStatus === 'REPORT_GENERATION_SUCCEEDED'
          ? 'success' : reportGenerationStatus === 'REPORT_GENERATION_FAILED' ? 'error' : 'info',
        placement: 'bottom',
        duration: 10
      });
    });
  }, [isLoading, corresponding_trackers_registered, mqttBrokerUrl, mqttBrokerUsername, mqttBrokerPassword]);
  return children;
};

MQTTWrapper.displayName = 'MQTTWrapper';

export default memo(MQTTWrapper);