import { Footer, Header } from 'containers/Layout';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

const MainLayout = () => (
  <>
    <Header />
    <Box
      display="flex"
      flex={1}
      p={4}
      alignItems="center"
      overflow="auto"
      justifyContent="center"
    >
      <Outlet />
    </Box>
    <Footer />
  </>
);

export default MainLayout;